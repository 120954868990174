import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./ClientStage.module.scss";
import { AutoSizer, List } from "react-virtualized";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedInUserAdmin } from "../../../../utils/entitySelector";
import DownloadCSVOrExcel from "../../../DownloadCSVOrExcel/v2";
import FeatureToggle from "../../../../components/FeatureToggle";
import {
  deselectBatchlang,
  deselectAllBatchlang,
  selectBatchlang,
  selectAllBatchlang,
} from "../../../../modules/downloads";

/* ----------------------------------------------------------
 * Hard coded consts to display the virtualized list properly
 * ---------------------------------------------------------- */

// Maximum height before virtualizing begins
const MAX_LIST_SIZE = 572;
// Height of the header row
const HEADER_HEIGHT = 60;
// Height of each row item
const ITEM_HEIGHT = 60;

export function DownloadButton({
  batchId,
  languageCode,
  stageId,
  projectId,
  showFinal,
}) {
  const isAdmin = useSelector(isLoggedInUserAdmin);

  const downloadButton = (
    <div className={styles.download}>
      <DownloadCSVOrExcel
        batchId={batchId}
        languageCode={languageCode}
        stageId={stageId}
        projectId={projectId}
        useCustomIcon
      />
    </div>
  );

  // always show download button for final stage, feature
  if (showFinal) {
    return downloadButton;
  }

  if (!isAdmin) return null;

  return (
    <FeatureToggle toggle={"withClientDownloads"}>
      {downloadButton}
    </FeatureToggle>
  );
}

const ClientStage = ({
  batchData,
  stageName,
  baseUrl,
  stageId,
  showFinal,
  projectId,
}) => {
  const [open, setOpen] = useState(true);
  const downloadBatchLangs = useSelector((state) => state.downloads);
  const dispatch = useDispatch();

  const toggleOnClick = () => {
    setOpen(!open);
  };

  const totalCount = batchData.reduce(
    (acc, data) => acc + data.deliverableCount,
    0
  );

  // find the estimated container height
  const containerHeight = HEADER_HEIGHT + ITEM_HEIGHT * batchData.length;

  // cap the height between a single row and a max size
  const height =
    containerHeight > MAX_LIST_SIZE
      ? MAX_LIST_SIZE
      : batchData.length === 0
      ? HEADER_HEIGHT + ITEM_HEIGHT // show empty stage row text
      : containerHeight;

  // the list has to not expand the extra height of the header row
  const baseHeight = height - HEADER_HEIGHT;
  const listHeight = showFinal && baseHeight < 300 ? 300 : baseHeight;
  // showFinal

  const onSelectAllBatches = (e) => {
    const { checked } = e.target;

    if (checked) {
      const params = { stageId, batches: batchData };
      dispatch(selectAllBatchlang(params));
    } else {
      dispatch(deselectAllBatchlang({ stageId }));
    }
  };

  function _rowRenderer(rowProps) {
    const { index, style } = rowProps;
    const {
      batchName,
      languageCode,
      deliverableCount,
      totalCount,
      deadline,
      batchId,
    } = batchData[index];

    const overdue = new Date(deadline) < new Date();

    const rowStyles = classNames({
      [styles.row]: true,
    });

    const deadlineStyles = classNames({
      [styles.feedbackDue]: true,
      [styles.overdue]: overdue,
    });

    const url =
      baseUrl && batchId && stageId
        ? `${baseUrl}/batches/${batchId}/stages/${stageId}?language=${languageCode}`
        : "";

    const id = `${batchId},${languageCode}`;

    const onChange = (e) => {
      const { checked } = e.target;

      const params = { stageId, batchId, languageCode };

      if (checked) {
        dispatch(selectBatchlang(params));
      } else {
        dispatch(deselectBatchlang(params));
      }
    };

    return (
      <Link key={url} className={rowStyles} style={style} to={url}>
        {showFinal && (
          <FeatureToggle toggle="merged-outputs">
            <div className={styles.checkboxContainer}>
              <input
                id={id}
                data-batch-id={batchId}
                onChange={onChange}
                onClick={(e) => e.stopPropagation()}
                type="checkbox"
                checked={downloadBatchLangs?.[stageId]?.[id] || false}
              />
              <label
                className={styles.label}
                htmlFor={id}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </FeatureToggle>
        )}
        <span className={styles.batch}>{batchName}</span>
        <span className={styles.counts}>
          {deliverableCount}/{totalCount}
        </span>

        {/* only show feedback due for in progress deliverables */}
        {!showFinal && <span className={deadlineStyles}>{deadline}</span>}
        <span className={styles.languageCode}>{languageCode}</span>

        <DownloadButton
          batchId={batchId}
          languageCode={languageCode}
          stageId={stageId}
          projectId={projectId}
          showFinal={showFinal}
        />
      </Link>
    );
  }

  return (
    <div key={stageId} className={styles.flexItem}>
      <div className={styles.stageHeader}>
        {showFinal && (
          <FeatureToggle toggle="merged-outputs">
            <div className={styles.checkboxContainer}>
              <input
                id="batch-lang-select-all"
                onChange={onSelectAllBatches}
                onClick={(e) => e.stopPropagation()}
                type="checkbox"
                checked={
                  downloadBatchLangs?.[stageId] &&
                  Object.values(downloadBatchLangs?.[stageId])?.filter(
                    (item) => item
                  )?.length === batchData.length
                }
              />
              <label
                className={styles.label}
                htmlFor="batch-lang-select-all"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </FeatureToggle>
        )}
        <div className={styles.stageName}>
          {stageName} <span className={styles.totalCount}>({totalCount})</span>
        </div>
        <div className={styles.showToggle}>
          <div className={styles.toggleContainer} onClick={toggleOnClick}>
            <svg className={styles.toggle} height="15px" width="16px">
              {" "}
              {open ? (
                <path d="M1 7h14v2h-14v-2z" fill="#fff" />
              ) : (
                <path d="M16 5.5l-1-1-7 7-7-7-1 1 8 8 8-8z" fill="#fff" />
              )}
            </svg>
          </div>
        </div>
      </div>

      {open && (
        <div className={styles.stageRow}>
          {batchData.length === 0 ? (
            <div className={styles.noContent}>
              No content currently available at this stage
            </div>
          ) : (
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  className={styles.list}
                  height={listHeight}
                  rowCount={batchData.length}
                  rowHeight={ITEM_HEIGHT}
                  rowRenderer={_rowRenderer}
                  stageId={stageId}
                  width={width}
                />
              )}
            </AutoSizer>
          )}
        </div>
      )}
    </div>
  );
};

ClientStage.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  batchData: PropTypes.arrayOf(
    PropTypes.shape({
      batchId: PropTypes.number.isRequired,
      batchName: PropTypes.string.isRequired,
      deadline: PropTypes.string,
      deliverableCount: PropTypes.number.isRequired,
      languageCode: PropTypes.string.isRequired,
      totalCount: PropTypes.number.isRequired,
    })
  ).isRequired,
  projectId: PropTypes.number,
  showFinal: PropTypes.bool,
  stageId: PropTypes.number.isRequired,
  stageName: PropTypes.string.isRequired,
};

export default ClientStage;
