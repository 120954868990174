import { createAction } from "redux-actions";
import { upsertData } from "../utils/normalize";
// import { postGraphQL } from "../../utils/fetch";
import { RESET_INITIAL_STATE } from "./me";
// import { CREATE_GRAMMAR_CHECK_MUTATION } from "./grammarCheckIgnores";

export const GRAMMAR_CHECK_LANGUAGES = {
  "en-GB": "en_GB",
  "fr-FR": "fr_FR",
};

// ------------------------------------
// Constants
// ------------------------------------
const WEB_SPELL_CHECKER_SUCCESS = "WEB_SPELL_CHECKER_SUCCESS";
const REMOVE_SPELL_CHECKER_SUGGESTION = "REMOVE_SPELL_CHECKER_SUGGESTION";
export const checkGrammarQuery = `webSpellChecker (deliverableIds: $deliverableIds) {
  grammarCheckId, deliverableId, taskFieldId, rawGrammarCheck, createDate, rawContent
}`;

export const fetchGrammarChecksQuery = `query webSpellChecker (
  $deliverableIds: String
) {
  ${checkGrammarQuery}
}`;

// ------------------------------------
// Actions
// ------------------------------------
export const webSpellCheckerSuccess = createAction(WEB_SPELL_CHECKER_SUCCESS);
export const removeSpellCheckerSuggestion = createAction(
  REMOVE_SPELL_CHECKER_SUGGESTION
);

export function processTransitionGrammarCheck(params, { projectId } = {}) {
  /* return async (dispatch) => {
    const { deliverableIds, rawContent, taskFieldId, languageCode } = params;

    const query = `mutation processGrammarChecks ($input: PostGrammarCheckInput) {
      processGrammarChecks (input: $input)
      {
        rawGrammarCheck, grammarCheckId, taskFieldId, deliverableId, createDate, rawContent
      }
    }`;

    try {
      if (languageCode && !GRAMMAR_CHECK_LANGUAGES[languageCode])
        return Promise.resolve();
      const { processGrammarChecks } = await postGraphQL(query, {
        input: {
          deliverableIds,
          rawContent,
          taskFieldId,
          languageCode,
          projectId,
        },
      });
      const grammarCheck = processGrammarChecks.map(
        ({ deliverableId, taskFieldId, rawGrammarCheck, createDate }) => {
          return {
            cellId: `${taskFieldId}-${deliverableId}`,
            deliverableId,
            taskFieldId,
            createDate,
            projectId,
            rawContent,
            corrections: JSON.parse(rawGrammarCheck),
          };
        }
      );
      dispatch(webSpellCheckerSuccess(grammarCheck));
    } catch (e) {}
  }; */
  return async (dispatch) => {
    return Promise.resolve();
  };
}

export async function createGrammarCheckIgnore(input) {
  /* try {
    const { createGrammarCheckIgnore } = await postGraphQL(
      CREATE_GRAMMAR_CHECK_MUTATION,
      { input }
    );

    return createGrammarCheckIgnore;
  } catch (err) {
    throw err;
  } */
  return Promise.resolve();
}

export const removeSuggestion = (suggestion) => {
  return async (dispatch) => {
    /* try {
      dispatch(webSpellCheckerSuccess([suggestion]));
    } catch (e) {} */
    return Promise.resolve();
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------

export const webSpellCheckerActionHandlers = {
  [RESET_INITIAL_STATE]: () => webSpellCheckerInitialState,
  [WEB_SPELL_CHECKER_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "cellId"),
};

export const webSpellCheckerInitialState = { entities: {}, result: [] };
