import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./WordsChecker.module.scss";

const WordList = ({
  wordsArray,
  highlightClass,
  emptyMessage,
  noUsedMessage,
}) => {
  const [showAllWords, setShowAllWords] = useState(false);

  // Memoize used/unUsedWords to prevent unnecessary recalculations
  const { usedWords, unUsedWords } = useMemo(() => {
    const usedWords = [];
    const unUsedWords = [];
    wordsArray.forEach(({ word, hasWord }) => {
      hasWord ? usedWords.push(word) : unUsedWords.push(word);
    });
    return { usedWords, unUsedWords };
  }, [wordsArray]);

  return (
    <div>
      {usedWords.length === 0 && unUsedWords.length > 0 && !showAllWords && (
        <span className={styles.requiredWords}>{noUsedMessage}</span>
      )}

      <span className={styles[highlightClass]}>{usedWords.join(", ")}</span>

      {showAllWords && usedWords.length > 0 && unUsedWords.length > 0 && (
        <span>, </span>
      )}

      {showAllWords && (
        <span className={styles.missingWord}>{unUsedWords.join(", ")}</span>
      )}

      {unUsedWords.length || usedWords.length > 0 ? (
        <button
          className={styles.showAll}
          onClick={() => setShowAllWords(!showAllWords)}
          aria-pressed={showAllWords}
        >
          {!showAllWords ? "Show all..." : "Show less"}
        </button>
      ) : (
        <span className={styles.requiredWords}>{emptyMessage}</span>
      )}
    </div>
  );
};

WordList.propTypes = {
  wordsArray: PropTypes.arrayOf(
    PropTypes.shape({
      word: PropTypes.string,
      hasWord: PropTypes.bool.isRequired,
    })
  ).isRequired,
  highlightClass: PropTypes.string.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  noUsedMessage: PropTypes.string.isRequired,
};

export default WordList;
