import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";
import WithData from "../../../../../decorators/WithData";
import AiEnhancementList from "../components/AiEnhancementList";
import { createQueryString } from "../../../../../utils/url";
import { showSuccessMessage } from "../../../../../modules/messagesV2";
import { createSelector } from "reselect";
import { downloadJobDetails } from "../../../../../modules/AiEnhancement";

const aiEnhancementsSelector = (state) => state.aiEnhancement.entities;

const sortedAiEnhancementsSelector = createSelector(
  (state) => state.aiEnhancement.entities,
  (aiEnhancements) =>
    Object.values(aiEnhancements).sort(
      (a: any, b: any) => b.aiEnhancementId - a.aiEnhancementId
    )
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps)),
  download: (data) => dispatch(downloadJobDetails(data)),
});

const mapStateToProps = (state) => {
  const aiEnhancements = sortedAiEnhancementsSelector(state);
  return {
    aiEnhancements,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(AiEnhancementList));
