import {
  AdminKeywordValidation,
  AdminBulkQA,
  UnauthorizedLayout,
  AdminTommyOutputs,
  Favourites,
  AdminAutomatedReminders,
  Archived,
  AdminGlossary,
  AIEnhancementNew,
  AIEnhancementList,
  AIEnhancementJobDetails,
} from "../bundles";

const routes = (_featureToggles) => [
  {
    component: AdminKeywordValidation,
    path: "/admin/keyword-validation-tool",
    exact: true,
  },
  {
    component: _featureToggles.bulkQA ? AdminBulkQA : UnauthorizedLayout,
    path: "/admin/projects/:projectId/assignments/:assignmentGroupId/all-tasks",
    exact: true,
  },
  {
    component: AdminTommyOutputs,
    path: "/admin/tommy-outputs",
    exact: true,
  },
  {
    component: AIEnhancementNew,
    path: "/admin/ai-enhancement/new",
    exact: true,
  },
  {
    component: AIEnhancementList,
    path: "/admin/ai-enhancement",
    exact: true,
  },
  {
    component: AIEnhancementJobDetails,
    path: "/admin/ai-enhancement/:jobId",
    exact: true,
  },
  {
    component: _featureToggles.automatedReminders
      ? AdminAutomatedReminders
      : UnauthorizedLayout,
    path: "/admin/projects/:projectId/automated-reminders",
    exact: true,
  },
  {
    component: _featureToggles.archivedPage ? Archived : UnauthorizedLayout,
    path: "/admin/archived",
    exact: true,
  },
  {
    component: _featureToggles.archivedPage ? Favourites : UnauthorizedLayout,
    path: "/admin/favourites",
    exact: true,
  },
  {
    component: _featureToggles.archivedPage ? Favourites : UnauthorizedLayout,
    path: "/admin/favourites",
    exact: true,
  },
  {
    component: _featureToggles.glossary_v2 ? AdminGlossary : UnauthorizedLayout,
    path: "/admin/glossary/:accountId",
    exact: true,
  },
];

export default routes;
