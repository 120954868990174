import { useMemo } from "react";
import Helmet from "react-helmet";
import PageHeader from "../../../../../components/PageHeader";
import OverlayLoading from "../../../../../components/OverlayLoading/v2";
import styles from "./AIEnhancementJobDetails.module.scss";
import Table from "./Table";

const AIEnhancementJobDetails = ({
  aiEnhancementJobDetails,
  downloadJobDetails,
  dataReady,
}) => {
  const { columns = [], data = [] } = useMemo(() => {
    if (
      !aiEnhancementJobDetails?.csvHeaders ||
      !aiEnhancementJobDetails?.csvRows
    ) {
      return { columns: [], rows: [] };
    }
    const columns = aiEnhancementJobDetails.csvHeaders?.map((header) => ({
      label: header.briefingFieldName,
      key: header.briefingFieldName,
    }));

    const data = aiEnhancementJobDetails.csvRows?.map((row) => {
      const fields = row.fields.reduce(
        (acc, field) => ({
          ...acc,
          [field.briefingFieldName]: field.briefingFieldValue,
        }),
        {}
      );
      return {
        URL: row.urlValue,
        ...fields,
      };
    });
    return { columns, data };
  }, [aiEnhancementJobDetails]);

  const {
    job_id: jobId,
    file_name: fileName,
    status,
  } = aiEnhancementJobDetails?.jobDetails || {};

  return (
    <>
      <Helmet>
        <title>AI Enhancement Job</title>
      </Helmet>

      <PageHeader
        breadCrumbItems={[
          { url: `/admin`, text: "Parent Accounts" },
          { url: `/admin/ai-enhancement`, text: "AI Enhancement List" },
          { text: "Job details" },
        ]}
        title={
          <div className={styles.headerWrapper}>
            <div>{jobId}</div>
            <div className={styles.jobStatusStyle}>{status}</div>
          </div>
        }
      >
        <div className={styles.pageHeaderRight}>
          <button
            className={styles.secondaryButton}
            type="button"
            onClick={() => downloadJobDetails({ jobId, fileName })}
          >
            Download
          </button>
        </div>
      </PageHeader>

      {dataReady ? (
        columns.length > 0 &&
        data.length > 0 && <Table columns={columns} data={data} />
      ) : (
        <OverlayLoading />
      )}
    </>
  );
};

export default AIEnhancementJobDetails;
