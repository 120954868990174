import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";
import WithData from "../../../../../decorators/WithData";
import AiEnhancementJobDetails from "../components/AIEnhancementJobDetails";
import { downloadJobDetails } from "../../../../../modules/AiEnhancement";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps)),
  downloadJobDetails: (data) => dispatch(downloadJobDetails(data)),
});

const mapStateToProps = (state) => ({
  aiEnhancementJobDetails: state.aiEnhancementJobDetails,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(AiEnhancementJobDetails));
