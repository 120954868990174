import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./WordsChecker.module.scss";
import WordList from "./WordList";
import classNames from "classnames";
import {
  sanitiseWord,
  wordBoundaryStart,
  wordBoundaryEnd,
} from "../../../../../../utils/string";
import stateFromHTML from "../../../../../../utils/stateFromHTML";

const noBoundaryLanguages = ["zh-Hans", "zh-Hant", "ja-JP", "ko-KR"];

/**
 * Preprocesses text by removing spaces and punctuation, keeping only letters and numbers.
 * @param {string} text - The text to preprocess.
 * @returns {string} The preprocessed text.
 */
const preprocessContent = (text) => {
  return typeof text === "string" ? text.replace(/[\s,.;!?]+/g, "") : "";
};

/**
 * Checks if a word (or part of it) exists in any element of the content array.
 * @param {string} word - The word to search for.
 * @param {string[]} contentArray - The array of strings to search in.
 * @param {string} languageCode - The language code to determine processing method.
 * @returns {boolean} True if the word is found, false otherwise.
 */
const hasWord = (word, contentArray, languageCode) => {
  const shouldPreprocess = noBoundaryLanguages.includes(languageCode);
  const processedWord = shouldPreprocess
    ? preprocessContent(word)
    : word.toLowerCase();

  const escapedWord = sanitiseWord(processedWord);
  // Create regex outside the loop for non-preprocessed searches
  const regex = !shouldPreprocess
    ? new RegExp(`${wordBoundaryStart}${escapedWord}${wordBoundaryEnd}`, "i")
    : null;

  return contentArray.some((content) => {
    if (typeof content !== "string") return false;
    if (shouldPreprocess) {
      return preprocessContent(content).includes(processedWord);
    } else {
      return regex.test(stateFromHTML(content).getPlainText(""));
    }
  });
};

const WordsChecker = ({
  content,
  words,
  languageCode,
  title,
  highlightClass,
  emptyMessage,
  noUsedMessage,
}) => {
  const [showKeywords, setShowKeywords] = useState(true);

  const wordsArray = useMemo(() => {
    return words.map((word) => ({
      word,
      hasWord: hasWord(word, content, languageCode),
    }));
  }, [words, content, languageCode]);

  return (
    <div className={styles.wordsContainer}>
      <button
        className={styles.wordNav}
        onClick={() => setShowKeywords(!showKeywords)}
        aria-expanded={showKeywords}
      >
        <span>{title}</span>
        <svg height="15px" width="15px">
          {showKeywords ? (
            <path d="M1 7h14v2h-14v-2z" fill="#da0034" />
          ) : (
            <path d="M16 5.5l-1-1-7 7-7-7-1 1 8 8 8-8z" fill="#da0034" />
          )}
        </svg>
      </button>
      <div
        className={classNames({
          [styles.visible]: showKeywords,
          [styles.hidden]: !showKeywords,
        })}
      >
        <WordList
          wordsArray={wordsArray}
          highlightClass={highlightClass}
          emptyMessage={emptyMessage}
          noUsedMessage={noUsedMessage}
        />
      </div>
    </div>
  );
};

WordsChecker.propTypes = {
  content: PropTypes.array.isRequired,
  words: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  highlightClass: PropTypes.string.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  noUsedMessage: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
};

export default WordsChecker;
