import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./ClientBatchStage.module.scss";
import Helmet from "react-helmet";
import BriefingFieldContainer from "../../components/BriefingFieldContainer";
import PageHeader from "../../components/PageHeader";
import OverlayLoading from "../../components/OverlayLoading/v1";
import parseQuery from "../../utils/parseQuery";
import { setLSItem } from "../../utils/localStorage";

class ClientBatchStage extends Component {
  componentDidMount() {
    const { language } = parseQuery(this.props.location.search);
    setLSItem("task-list-language-code", language);
  }

  render() {
    if (!this.props.dataReady) return <OverlayLoading />;

    const {
      accountName,
      baseUrlRoot,
      batchName,
      isClient,
      match: {
        params: { projectId, batchId, stageId },
      },
      orderFormName,
      project: { projectName },
      stageName,
      unresolvedBatchCommentGroup,
      unresolvedCommentGroups,
    } = this.props;
    const { language } = parseQuery(this.props.location.search);
    /* eslint-disable max-len */
    const baseUrl = `/${baseUrlRoot}/projects/${projectId}/batches/${batchId}/stages/${stageId}/deliverables`;

    const YOUVE_REVIEWED_ALL_NEW_CONTENT = `You’ve reviewed all the new content in this batch. You’ll be notified by email when there’s more to review. In the meantime, head back to your dashboard to view and download any approved content.`;

    const NO_DATA_AVAILABLE_TEXT = {
      "Content in amends": YOUVE_REVIEWED_ALL_NEW_CONTENT,
      "Amended content for review": `You’ve reviewed all the amended content in this batch. You’ll be notified by email when there’s more to review. In the meantime, head back to your dashboard to view and download any approved content.`,
      "New content for review": YOUVE_REVIEWED_ALL_NEW_CONTENT,
    };
    const NO_DATA_AVAILABLE_DEFAULT_MESSAGE = `No default message for stageName: '${stageName}'`;
    /* eslint-enable max-len */

    return (
      <div>
        <Helmet>
          {" "}
          <title>Batch List</title>{" "}
        </Helmet>
        <div className={styles.header}>
          <PageHeader
            breadCrumbItems={[
              { url: `/${baseUrlRoot}`, text: `${accountName}` },
              {
                text: `${orderFormName} | ${projectName}`,
                url: isClient
                  ? `/${baseUrlRoot}/projects/${projectId}/overview`
                  : `/${baseUrlRoot}/projects/${projectId}`,
              },
              { text: `${batchName} - ${stageName}` },
            ]}
            title={projectName}
          />
        </div>

        <BriefingFieldContainer
          allowClientChanges={this.props.allowClientChanges}
          baseUrl={baseUrl}
          briefingFields={this.props.briefingFields}
          data={this.props.deliverables}
          noDataAvailableText={
            NO_DATA_AVAILABLE_TEXT[stageName] ||
            NO_DATA_AVAILABLE_DEFAULT_MESSAGE
          }
          processMultipleTransitions={
            this.props.createTransitionLogsFromDeliverables
          }
          transitions={this.props.transitions}
          unresolvedCommentGroups={unresolvedCommentGroups}
          unresolvedBatchCommentGroup={unresolvedBatchCommentGroup}
          shouldShowLanguageMismatchModal={
            this.props.shouldShowLanguageMismatchModal
          }
          languageCode={language}
        />
      </div>
    );
  }
}

ClientBatchStage.propTypes = {
  accountName: PropTypes.string,
  baseUrlRoot: PropTypes.string,
  batchName: PropTypes.string,
  briefingFields: PropTypes.arrayOf(
    PropTypes.shape({
      briefingFieldId: PropTypes.number.isRequired,
      briefingFieldName: PropTypes.string.isRequired,
    })
  ).isRequired,
  createTransitionLogsFromDeliverables: PropTypes.func.isRequired,
  dataReady: PropTypes.bool.isRequired,
  deliverables: PropTypes.array.isRequired,
  isClient: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountId: PropTypes.string,
      batchId: PropTypes.string.isRequired,
      orderFormId: PropTypes.string,
      projectId: PropTypes.string.isRequired,
      stageId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  orderFormName: PropTypes.string,
  project: PropTypes.shape({
    projectName: PropTypes.string,
  }).isRequired,
  shouldShowLanguageMismatchModal: PropTypes.bool,
  stageName: PropTypes.string.isRequired,
  transitions: PropTypes.arrayOf(
    PropTypes.shape({
      fromStageId: PropTypes.number.isRequired,
      payStageId: PropTypes.number,
      toStageId: PropTypes.number.isRequired,
      transitionId: PropTypes.number.isRequired,
      transitionName: PropTypes.string.isRequired,
      workflowId: PropTypes.number.isRequired,
    })
  ).isRequired,
  unresolvedBatchCommentGroup: PropTypes.object,
  unresolvedCommentGroups: PropTypes.arrayOf(
    PropTypes.shape({
      deliverableId: PropTypes.string,
      archived: PropTypes.bool,
      cleared: PropTypes.bool,
      resolved: PropTypes.bool,
    })
  ),
};

export default ClientBatchStage;
