import React from "react";
import PropTypes from "prop-types";
import styles from "./NewFieldAddButton.module.scss";

const NewFieldAddButton = (props) => {
  const handleAddField = () => {
    props.addField(props.fieldComponent);
  };

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={styles.addButton}
        onClick={handleAddField}
      >
        Add New
      </button>
    </div>
  );
};

NewFieldAddButton.propTypes = {
  addField: PropTypes.func.isRequired,
  fieldComponent: PropTypes.func.isRequired,
};

export default NewFieldAddButton;
