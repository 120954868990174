import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import TaskNavigation from "../../_components/TaskNavigation/TaskNavigation";
import AssignmentEdit from "../..";
import BatchFeedbackPanel from "../../../../components/BatchFeedbackPanel";

/**
 * Render AssignmentEdit using a deliverable's details
 */
const ByDeliverable = ({
  batchId,
  deliverableId,
  deliverable: { batchGroupDeliverableIds, currentStageId, languageCode },
  history,
  isCommentable,
  isCurrentStage,
  isEditable,
  listUrl,
  nextUrl,
  previousUrl,
  projectId,
  shouldShowLanguageMismatchModal,
  stageId,
  resolved,
}) => {
  // Redirect if given stage doesn't match current stage
  if (currentStageId && currentStageId !== stageId) {
    return <Redirect to="/" />;
  }

  const taskNavigation = (
    <TaskNavigation
      currentDeliverableId={deliverableId}
      deliverableIds={batchGroupDeliverableIds}
      history={history}
      nextUrl={nextUrl}
      previousUrl={previousUrl}
    />
  );

  return (
    <div>
      {deliverableId && projectId && stageId && (
        <AssignmentEdit
          batchId={batchId}
          deliverableId={deliverableId}
          history={history}
          isActionable
          isCommentable={isCommentable}
          isCurrentStage={isCurrentStage}
          isEditable={isEditable}
          listUrl={listUrl}
          nextUrl={nextUrl}
          projectId={projectId}
          stageId={stageId}
          taskNavigationComponent={taskNavigation}
          shouldShowLanguageMismatchModal={shouldShowLanguageMismatchModal}
        />
      )}

      {batchId && languageCode && (
        <BatchFeedbackPanel
          batchId={batchId}
          languageCode={languageCode}
          projectId={projectId}
          resolved={resolved}
        />
      )}
    </div>
  );
};

ByDeliverable.propTypes = {
  batchId: PropTypes.number,
  deliverable: PropTypes.shape({
    batchGroupDeliverableIds: PropTypes.array,
    currentStageId: PropTypes.number,
    languageCode: PropTypes.string,
  }),
  deliverableId: PropTypes.number.isRequired,
  history: PropTypes.object,
  isCommentable: PropTypes.bool,
  isCurrentStage: PropTypes.bool,
  isEditable: PropTypes.bool,
  listUrl: PropTypes.string,
  nextUrl: PropTypes.string,
  previousUrl: PropTypes.string,
  projectId: PropTypes.number,
  shouldShowLanguageMismatchModal: PropTypes.bool,
  stageId: PropTypes.number,
  resolved: PropTypes.bool,
};

export default ByDeliverable;
