import { createAction } from "redux-actions";
import { upsertData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";
import { createQueryString } from "../utils/url";
import { showSuccessMessage } from "../modules/messagesV2";

export const aiEnhancementQuery = `
  aiEnhancement {
    aiEnhancementId
    jobId
    projectName
    fullName
    accountName
    createDate
    status
    fileName
  }
`;

export const downloadJobDetails = ({ jobId, fileName }) => {
  return async (dispatch) => {
    const params = {
      jobId,
      fileName,
      format: "csv",
    };

    const queryString = createQueryString(params);
    const API_GATEWAY =
      (window as any).__API_GATEWAY__ || process.env.REACT_APP_API_GATEWAY;

    (window.location as Location).assign(
      `${API_GATEWAY}/ai-enhancement/report?${queryString}`
    );

    dispatch(showSuccessMessage("Download successful"));
  };
};

export const FETCH_AI_ENHANCEMENT_SUCCESS = "FETCH_AI_ENHANCEMENT_SUCCESS";
export const fetchAiEnhancementSuccess = createAction(
  FETCH_AI_ENHANCEMENT_SUCCESS
);

export const AiEnhancementHandler = {
  [RESET_INITIAL_STATE]: () => aiEnhancementInitialState,
  [FETCH_AI_ENHANCEMENT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "aiEnhancementId"),
};

export const aiEnhancementInitialState = { entities: {}, result: [] };
