import { createAction } from "redux-actions";

const SELECT_BATCH_LANG = "SELECT_BATCH_LANG";
export const selectBatchlang = createAction(SELECT_BATCH_LANG);
const DESELECT_BATCH_LANG = "DESELECT_BATCH_LANG";
export const deselectBatchlang = createAction(DESELECT_BATCH_LANG);
const SELECT_ALL_BATCH_LANG = "SELECT_ALL_BATCH_LANG";
export const selectAllBatchlang = createAction(SELECT_ALL_BATCH_LANG);
const DESELECT_ALL_BATCH_LANG = "DESELECT_ALL_BATCH_LANG";
export const deselectAllBatchlang = createAction(DESELECT_ALL_BATCH_LANG);

export const downloadActionHandlers = {
  [SELECT_BATCH_LANG]: (
    state,
    { payload: { batchId, languageCode, stageId } }
  ) => {
    const batchLang = `${batchId},${languageCode}`;
    return {
      ...state,
      [stageId]: {
        ...state[stageId],
        [batchLang]: true,
      },
    };
  },
  [DESELECT_BATCH_LANG]: (
    state,
    { payload: { batchId, languageCode, stageId } }
  ) => {
    const batchLang = `${batchId},${languageCode}`;

    // first remove the stage object
    const { [stageId]: stageData, ...rest } = state;
    // then remove the batchLang from the object
    const { [batchLang]: _removed, ...stageRest } = stageData;

    // if any batch langs exist add them back in
    const newStageObj =
      Object.keys(stageRest).length > 0 ? { [stageId]: stageRest } : {};

    return {
      ...rest,
      ...newStageObj,
    };
  },
  [SELECT_ALL_BATCH_LANG]: (state, { payload: { stageId, batches } }) => {
    const selectedBatches = batches.reduce((acc, item) => {
      const { batchId, languageCode } = item;
      const batchLang = `${batchId},${languageCode}`;
      return {
        ...acc,
        [batchLang]: true,
      };
    }, {});
    return {
      ...state,
      [stageId]: selectedBatches,
    };
  },
  [DESELECT_ALL_BATCH_LANG]: (state, { payload: { stageId } }) => {
    return {
      ...state,
      [stageId]: {},
    };
  },
};

export const downloadInitialState = {};
