import React from "react";
import styles from "./Table.module.scss"; // Import SCSS Module styles

const Table = ({ data, columns }) => {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col} className={styles.th}>
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((col, colIndex) => (
                <td key={colIndex} className={styles.td}>
                  {col.key === "URL" ? (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={row[col.key]}
                    >
                      {row[col.key]}
                    </a>
                  ) : (
                    row[col.key]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
