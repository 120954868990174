import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";

export const FETCH_AI_ENHANCEMENT_JOB_DETAILS_SUCCESS =
  "FETCH_AI_ENHANCEMENT_JOB_DETAILS_SUCCESS";
export const fetchAiEnhancementJobDetailsSuccess = createAction(
  FETCH_AI_ENHANCEMENT_JOB_DETAILS_SUCCESS
);

export const AiEnhancementJobDetailsHandler = {
  [RESET_INITIAL_STATE]: () => aiEnhancementJobDetailsInitialState,
  [FETCH_AI_ENHANCEMENT_JOB_DETAILS_SUCCESS]: (state, { payload }) => {
    return payload;
  },
};

export const aiEnhancementJobDetailsInitialState = {};
