import { fetchAiEnhancementJobDetailsSuccess } from "../../../../../modules/AiEnhancementJobDetails";
import { createQueryString } from "../../../../../utils/url";

export function getInitialData(data) {
  return async (dispatch) => {
    const {
      match: {
        params: { jobId },
      },
    } = data;

    const queryParams = {
      jobId,
      format: "json",
    };
    const queryString = createQueryString(queryParams);

    const API_GATEWAY =
      (window as any).__API_GATEWAY__ || process.env.REACT_APP_API_GATEWAY;

    const url = `${API_GATEWAY}/ai-enhancement/report?${queryString}`;

    const response = await fetch(url, { credentials: "include" });
    const json = await response.json();

    if (response.status !== 200) {
      throw new Error(json);
    }

    dispatch(fetchAiEnhancementJobDetailsSuccess(json));
  };
}
