import { createAction } from "redux-actions";
import { createSelector } from "reselect";
import { RESET_INITIAL_STATE } from "./me";
import { updateBatchSuccess } from "../modules/batches";
import { postGraphQL } from "../../utils/fetch";
import { capitalize } from "../utils/formatting";
import { upsertData } from "../utils/normalize";
import { nullObject } from "../utils/nullObjects";

export const CommentType = Object.freeze({
  Field: "field",
  Inline: "inline",
});

// TODO: Replace commentGroupFields with commented line and utilize it in the subtype fields
// export const commentGroupFields = `commentGroupId, cleared, archived, resolved`
export const commentGroupFields = `commentGroupId, atStage, deliverableId, taskFieldId, cleared, commentType, archived, resolved`;
export const commentGroupDeliverableFields = `commentGroupId, atStage, deliverableId, taskFieldId, cleared, commentType, archived`;
export const commentGroupBatchFields =
  "commentGroupId, batchId, languageCode, cleared, archived, resolved";

// TODO: Remove `commentGroups` queries after decommission
export const commentGroupsByAssignmentIdQuery = `commentGroups (assignmentId: $assignmentId) {
    ${commentGroupFields}
  }`;

export const commentGroupsByDeliverableIdQuery = `commentGroups (deliverableId: $deliverableId) {
    ${commentGroupFields}
  }`;

export const commentGroupsByAssignmentGroupQuery = `commentGroups (assignmentGroupId: $assignmentGroupId) {
  ${commentGroupFields}
}`;

export const commentGroupsByBatchIdQuery = `commentGroups (batchId: $batchId) {
  ${commentGroupFields}
}`;

export const commentGroupBatchesByBatchIdQuery = `commentGroupBatches (batchId: $batchId) {
  ${commentGroupBatchFields}
}`;

export const commentGroupDeliverablesByAssignmentIdQuery = `commentGroupDeliverables (assignmentId: $assignmentId) {
  ${commentGroupDeliverableFields}
}`;

export const commentGroupDeliverablesByAssignmentGroupIdQuery = `commentGroupDeliverables (assignmentGroupId: $assignmentGroupId) {
  ${commentGroupDeliverableFields}
}`;

export const commentGroupDeliverablesByDeliverableIdQuery = `commentGroupDeliverables (deliverableId: $deliverableId) {
  ${commentGroupDeliverableFields}
}`;

export const resolveCommentGroup = (input) => {
  return async (dispatch) => {
    const query = `mutation resolveCommentGroup($input: CommentGroupInput) {
      resolveCommentGroup(input: $input) {
        commentGroupId, resolved
      }
    }`;

    try {
      const json = await postGraphQL(query, { input });
      dispatch(updateCommentGroupsSuccess(json.resolveCommentGroup));
    } catch (err) {
      console.error("resolveComment ERROR", err);
      return err;
    }
  };
};

export function createCommentGroup(input, commentGroupType = "deliverable") {
  return (dispatch, getState) => {
    const { featureToggles } = getState();
    const batchFeedbackEnabled = featureToggles.QCC_1149_batchFeedback;
    if (!batchFeedbackEnabled) commentGroupType = "";

    const titleCaseCommentGroupType = batchFeedbackEnabled
      ? capitalize(commentGroupType)
      : "";

    const mutationName = batchFeedbackEnabled
      ? `createCommentGroup${titleCaseCommentGroupType}`
      : "createCommentGroup";

    let fields = commentGroupFields;
    if (commentGroupType === "deliverable")
      fields = commentGroupDeliverableFields;

    if (commentGroupType === "batch") {
      fields = commentGroupBatchFields;
    }

    const query = `mutation ${mutationName} ($input: CommentGroup${titleCaseCommentGroupType}Input) {
      ${mutationName} (input: $input) {
        ${fields}
      }
    }`;

    return postGraphQL(query, { input }, mutationName)
      .then((json) => {
        dispatch(createCommentGroupSuccess(json));
        dispatch(
          updateBatchSuccess({
            batchId: json.batchId,
            commentGroupId: json.commentGroupId,
          })
        );
        return json;
      })
      .catch((err) => {
        console.log(`${mutationName} ERROR`, err);
        return err;
      });
  };
}

/**
 * Returns all uncleared, unarchived comment groups for a given deliverable
 * along with their comments
 *
 * @param   {Object}    state
 * @param   {number}    deliverableId
 * @param   {number}    stageId
 *
 * @returns {Object[]}  comment groups with an array of their comments
 */
export const commentGroupsByDeliverableSelector = createSelector(
  (state) => state.commentGroups.entities,
  (state) => state.comments.entities,
  (state) => state.stageCommentGroups,
  (_, deliverableId) => deliverableId,
  (_, _delId, stageId) => stageId,
  (
    commentGroups,
    commentEntities,
    stageCommentGroups,
    deliverableId,
    currentStageId
  ) => {
    const visibleForCurrentStage = stageCommentGroups.reduce(
      (acc, { atStage, forStage }) => {
        if (forStage === currentStageId) acc[atStage] = true;
        return acc;
      },
      {}
    );

    const comments = Object.values(commentEntities);

    return Object.values(commentGroups)
      .filter(
        ({ archived, atStage, cleared, deliverableId: groupDeliverableId }) =>
          groupDeliverableId === deliverableId &&
          !archived &&
          !cleared &&
          (atStage === currentStageId || visibleForCurrentStage[atStage])
      )
      .map((group) => {
        const groupComments = comments
          .filter(
            ({ commentGroupId }) => commentGroupId === group.commentGroupId
          )
          .sort((a, b) => {
            if (a.createDate < b.createDate) {
              return -1;
            }
            if (a.createDate > b.createDate) {
              return 1;
            }
            return 0;
          });

        return { ...group, comments: groupComments };
      })
      .filter(
        ({ comments }) =>
          comments.length > 0 && comments.some(({ archived }) => !archived)
      );
  }
);

/**
 * Return a batch comment group for a batch and language
 *
 * @param   {Object}    state
 * @param   {number}    batchId
 * @param   {string}    languageCode
 * @returns {Object}    comment group object containing a 'comments' array
 */
export const batchCommentGroupSelector = createSelector(
  (state) => state.commentGroups.entities,
  (state) => state.comments.entities,
  (_, batchId) => batchId,
  (_state, _batchId, languageCode) => languageCode,
  (commentGroups, comments, batchId, languageCode) => {
    const commentGroup = Object.values(commentGroups).find(
      (cg) => cg.batchId === batchId && cg.languageCode === languageCode
    );

    if (!commentGroup) return nullObject;

    // Get comments
    const { commentGroupId, resolved } = commentGroup;
    const batchComments = Object.values(comments)
      .filter((c) => c.commentGroupId === commentGroupId && !c.archived)
      .sort((a, b) => new Date(b.createDate) - new Date(a.createDate));

    return {
      ...commentGroup,
      comments: batchComments,
      resolved,
    };
  }
);

export const unresolvedBatchCommentGroupSelector = createSelector(
  (state) => state.commentGroups.entities,
  (_, batchId) => batchId,
  (_, _batchId, languageCode) => languageCode,
  (commentGroups, batchId, languageCode) => {
    const commentGroup = Object.values(commentGroups).find(
      (cg) => cg.batchId === batchId && cg.languageCode === languageCode
    );
    if (commentGroup) {
      const { archived, cleared, resolved } = commentGroup;
      const isUnresolvedCommentGroup = !archived && !cleared && !resolved;
      return isUnresolvedCommentGroup ? commentGroup : null;
    }
    return null;
  }
);

export const unresolvedCommentGroupsSelector = createSelector(
  (commentGroups) => commentGroups.entities,
  (commentGroups) => {
    return Object.values(commentGroups).filter((commentGroup) => {
      const { archived, cleared, resolved } = commentGroup;
      return !archived && !cleared && !resolved;
    });
  }
);

/**
 * Create a selector to retrieve all comment groups for a given task
 * and index them by comment group id
 */
export const taskCommentGroupsSelectorFactory = () => {
  /**
   * @param   {Object}    state
   * @param   {number}    deliverableId
   * @param   {number}    stageId
   * @param   {number}    taskFieldId
   * @returns {Object}    comment groups (and their comments) indexed by
   *  comment group id
   */
  return createSelector(
    commentGroupsByDeliverableSelector,
    (_state, _delId, _stageId, taskFieldId) => taskFieldId,
    (commentGroups, taskFieldId) => {
      return commentGroups
        .filter(
          ({ taskFieldId: groupTaskFieldId }) =>
            groupTaskFieldId === taskFieldId
        )
        .reduce((acc, cur) => {
          acc[cur.commentGroupId] = cur;
          return acc;
        }, {});
    }
  );
};

export const FETCH_COMMENT_GROUPS = "FETCH_COMMENT_GROUPS";
export const CREATE_COMMENT_GROUP_SUCCESS = "CREATE_COMMENT_GROUP_SUCCESS";
export const UPDATE_COMMENT_GROUP_SUCCESS = "UPDATE_COMMENT_GROUP_SUCCESS";

export const fetchCommentGroupsSuccess = createAction("FETCH_COMMENT_GROUPS");
export const createCommentGroupSuccess = createAction(
  "CREATE_COMMENT_GROUP_SUCCESS"
);
export const updateCommentGroupsSuccess = createAction(
  "UPDATE_COMMENT_GROUP_SUCCESS"
);

export const commentGroupActionHandlers = {
  [RESET_INITIAL_STATE]: () => commentGroupInitialState,
  [CREATE_COMMENT_GROUP_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "commentGroupId"),
  [UPDATE_COMMENT_GROUP_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "commentGroupId"),
  [FETCH_COMMENT_GROUPS]: (state, { payload }) =>
    upsertData(state, payload, "commentGroupId"),
};

export const commentGroupInitialState = { entities: {}, result: [] };
