import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { PersonType } from "../../../modules/people";
import { getLoggedInUser } from "../../../utils/entitySelector";

export const Permissions = {
  FREELANCER_BULK_QA: "FREELANCER_BULK_QA",
};

type PermissionsResponse = {
  data?: {
    personPermissions: {
      permissionName: string;
    }[];
  };
  error?: object;
};
type PropTypes = {
  children: JSX.Element;
  permission: string;
  redirectTo?: string;
};

export const PERMISSIONS_QUERY = gql`
  query person_permissions($personId: ID) {
    personPermissions(personId: $personId) {
      personId
      permissionName
    }
  }
`;

function FreelancerGuard(props: PropTypes): JSX.Element | null {
  const { children, permission, redirectTo } = props;
  const { personId, personType } = useSelector(getLoggedInUser);

  const { data, error }: PermissionsResponse = useQuery(PERMISSIONS_QUERY, {
    variables: { personId },
    skip: personType === PersonType.Freelancer,
  });

  // only guard against freelancers
  if (personType === PersonType.Freelancer) {
    return children;
  }

  // no data or error do not render component
  if (!data || error) return null;

  const hasPermissions = data.personPermissions.some(({ permissionName }) => {
    return permissionName === permission;
  });

  if (redirectTo && !hasPermissions) {
    return <Redirect to={redirectTo} />;
  }

  return hasPermissions ? children : null;
}

export default FreelancerGuard;
