import React from "react";
import PropTypes from "prop-types";
import OverlayLoading from "../../../../../../../../components/OverlayLoading/v1";
import ProgressTab from "../../../../../../../../components/ProgressTab/v3";

const WithClient = (props) => {
  const { dataReady, featureToggles, history, location, projectId } = props;
  if (!dataReady) return <OverlayLoading />;

  const baseUrl = `/admin/projects/${projectId}`;

  return (
    <ProgressTab
      baseUrl={baseUrl}
      featureToggles={featureToggles}
      history={history}
      location={location}
      projectId={projectId}
      selectedStageTypes={["Amends", "Client"]}
      isMultiSelectLanguageFilter
    />
  );
};

WithClient.propTypes = {
  dataReady: PropTypes.bool.isRequired,
  featureToggles: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  projectId: PropTypes.number.isRequired,
};

export default WithClient;
