import { connect } from "react-redux";
import { hideAppComponent } from "../../../modules/appComponents";
import {
  batchCommentGroupSelector,
  createCommentGroup,
  resolveCommentGroup,
} from "../../../modules/commentGroups";
import { createComment, archiveComment } from "../../../modules/comments";
import BatchFeedbackPanel from "../components/BatchFeedbackPanel";
import { getInitialData } from "../modules/getInitialData";
import { createSelector } from "reselect";
import { showModal, hideModal } from "../../../modules/modal";
import { clientsSelector } from "../../AssignmentEdit/Tasks/_components/CommentGroup/containers/CommentGroup";

const mapDispatchToProps = (dispatch, ownProps) => {
  const { batchId, projectId } = ownProps;

  return {
    getInitialData: () => dispatch(getInitialData({ batchId, projectId })),
    createBatchComment: async (
      comment,
      personId,
      commentGroupId,
      languageCode,
      commentBox
    ) => {
      let newCommentGroup;
      if (!commentGroupId) {
        newCommentGroup = await dispatch(
          createCommentGroup({ batchId, languageCode }, "batch")
        );
      }

      dispatch(
        createComment(
          commentGroupId || newCommentGroup.commentGroupId,
          personId,
          comment,
          commentBox
        )
      );
    },

    resolveCommentGroup: (input) => {
      dispatch(resolveCommentGroup(input));
    },

    archiveBatchComment: (commentId, editorState) => {
      dispatch(archiveComment(commentId, editorState));
    },

    hideBatchFeedbackPanel: () =>
      dispatch(hideAppComponent("batchFeedbackPanel")),
    showModal: (modalType) => dispatch(showModal({ type: modalType })),
    hideModal: () => dispatch(hideModal()),
  };
};

const mapStateToProps = (state, ownProps) => {
  const batchId = Number(ownProps.batchId);
  const { languageCode } = ownProps;

  const { commentGroupId, comments, resolved } = batchCommentGroupSelector(
    state,
    batchId,
    languageCode
  );

  const clients = clientsSelector(state);

  return {
    batchId,
    commentGroupId,
    comments,
    clients,
    display: state.appComponents.batchFeedbackPanel.display,
    personId: Number(state.me),
    resolved,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchFeedbackPanel);
