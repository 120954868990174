import React from "react";
import PropTypes from "prop-types";
import Nav from "../../../../components/Nav";
import Helmet from "react-helmet";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import { getPeopleListUrl } from "../../../../routes";
import QuillRedirect from "../../../../components/QuillRedirect";
import MigrationMaintenanceBanner from "../../../../components/MigrationMaintenanceBanner";
import { core, marginLeft } from "./AdminLayout.module.scss";

export const AdminLayout = (props) => {
  if (!props.me) {
    return <QuillRedirect to="/" />;
  }
  if (props.me.personType === "Client") {
    return <Redirect to="/clients" />;
  }
  if (props.me.personType === "Freelancer") {
    return <Redirect to="/freelancer" />;
  }
  const paymentUrl = "/admin/payments/new";

  const items = [
    {
      url: "/admin",
      iconName: "Home",
      active: props.location.pathname === "/admin",
      hoverText: "Dashboard",
    },
  ];

  // TODO: QCC-1955 Temporary admin user hack for First Choice
  // Hide all important info from the user
  if (props.me && props.me.personId !== 2670) {
    items.push(
      ...[
        {
          url: paymentUrl,
          iconName: "Payments",
          active: props.location.pathname.substr(0, 15) === "/admin/payments",
          hoverText: "Payments",
        },
        {
          url: getPeopleListUrl(props.featureToggles),
          iconName: "People",
          active: props.location.pathname.startsWith("/admin/people"),
          hoverText: "People",
        },
      ]
    );
  }

  items.push(
    {
      url: "/admin/favourites",
      iconName: "Favorites",
      active: props.location.pathname === "/admin/favourites",
      hoverText: "Favourites",
    },
    {
      url: "/admin/archived",
      iconName: "Archived",
      active: props.location.pathname === "/admin/archived",
      hoverText: "Archived",
    }
  );

  if (props.featureToggles.QCC_1658_creatorHubNavLink) {
    items.push({
      url: "//sites.google.com/jellyfish.com/lshelpcenter/home",
      iconName: "Helpdesk",
      src: "/icons/nav/creator-hub.svg",
      hoverText: "J+ Scribe Help Center",
    });
  }

  return (
    <div className={core}>
      <Helmet defaultTitle="J+ Scribe">
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
      </Helmet>
      <Nav
        home="/admin"
        items={items}
        logout={props.logout}
        personType={props.me.personType}
      />

      <MigrationMaintenanceBanner />

      <div className={marginLeft}>{renderRoutes(props.route.routes)}</div>
    </div>
  );
};

AdminLayout.propTypes = {
  featureToggles: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
  me: PropTypes.shape({
    personType: PropTypes.string.isRequired,
  }),
  route: PropTypes.object.isRequired,
};

export default AdminLayout;
