import React from "react";
import styles from "./PageHeader.module.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const PageHeader = (props) => {
  const hasBreadCrumbs = props.breadCrumbItems !== undefined;
  const pageHeaderStyles = hasBreadCrumbs
    ? styles.pageHeader
    : styles.withoutBreadCrumbs;

  return (
    <div className={pageHeaderStyles}>
      <div className={styles.title}>{props.title}</div>

      {hasBreadCrumbs && (
        <div className={styles.breadCrumbsContainer}>
          {props.breadCrumbItems.map(({ url, text }, idx) => {
            if (idx === props.breadCrumbItems.length - 1) {
              return (
                <div key={idx} className={styles.breadCrumbItem}>
                  {text}
                </div>
              );
            }
            return (
              <div key={idx} className={styles.breadCrumbItem}>
                {url ? (
                  <Link className={styles.link} to={url}>
                    {text}
                  </Link>
                ) : (
                  <span>{text}</span>
                )}
                <span>&nbsp;&gt;&nbsp;</span>
              </div>
            );
          })}
        </div>
      )}
      {props.children}
    </div>
  );
};

PageHeader.propTypes = {
  breadCrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default PageHeader;
