import classNames from "classnames";
import styles from "./Table.module.scss";

const Table = ({
  data,
  headers,
  download,
  onRowClick,
  hasRowClickDisabled,
}) => {
  const handleDownload = async (data) => {
    await download(data);
  };

  return (
    <div className={styles.table}>
      <div className={styles.tableHeader}>
        {headers.map((header, index) => (
          <div key={index} className={styles.tableCell}>
            {header.label}
          </div>
        ))}
      </div>
      <div className={styles.tableBody}>
        {data.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className={classNames({
              [styles.tableRow]: true,
              [styles.tableRowHover]:
                !!onRowClick &&
                (hasRowClickDisabled ? !hasRowClickDisabled(row) : true),
            })}
            onClick={onRowClick ? () => onRowClick(row) : undefined}
          >
            {headers.map((header, cellIndex) => (
              <div key={cellIndex} className={styles.tableCell}>
                {header.key !== "Actions" ? (
                  row[header.key]
                ) : (
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDownload({
                        jobId: row["jobId"],
                        fileName: row["fileName"],
                      });
                    }}
                    className={styles.downloadButton}
                  >
                    Download
                  </button>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;
